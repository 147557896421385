import React, { useEffect, useState } from "react";
import Group from "./images/Group.png";
import {
    FaList,
    FaHistory,
    FaLock,
    FaSearch,
    FaCloudDownloadAlt,
    FaFilter,
    FaPlus,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EmployeeList from "./EmployeeList";
import History from "./History";
import axios from "axios";
import AddUser from "./AddUser";
import BaseUrl from "../BaseUrl";
import {ToastContainer} from 'react-toastify';

const HomePage_one = () => {
    const navigate = useNavigate();
    let csvfile;
    let csv_data = [];
    const [dataHistEmp, setDataHistEmp] = useState([]);
    const [employee_list_state, set_employee_list_state] = useState(true);
    const [history_state, set_history_state] = useState(false);
    const [add_user_state, set_add_user_state] = useState(false);

    const [search_val, set_search_val] = useState("");
    const [filter, set_filter] = useState(false);
    const [status_val, set_status_val] = useState("all_roll");
    const [flag_val, set_flag_val] = useState("all_block");

    function dwnldCsv() {
        csvfile = "Employee_Id,Employee_Name,Status,Flag,Last_Blocked,Last_Unblocked" + "\n";
        csv_data.forEach((element) => {
            csvfile += element + "\n";
        });

        dwnld("EmployeeList.csv", csvfile);
    }

    function dwnld(filename, csvfile) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(csvfile)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    useEffect(() => {
        <EmployeeList />;
    });

    useEffect(() => {
        axios({
            method: "GET",
            url: "https://www.camerablocker.io/policy_app/app/emp-status/999/999",
            // url: "http://54.205.93.121/policy_app/app/emp-status/999/999"
        })
            .then((response) => {
                setDataHistEmp(response.data.records);
            })
            .catch((e) => console.log(e));
    }, []);

    return document.cookie === "username=admin001" || document.cookie === "username=admin002" ? (
        <div className=" bg-dark-blue h-screen flex flex-row">
            <div className=" left_drawer flex flex-col mt-5 w-1/5 text-text-white">
                <div className=" h-fit w-full flex justify-center">
                    <img src={Group} />
                </div>
                <div className=" pages_class">
                    {employee_list_state === true || add_user_state === true ? (
                        <div
                            className=" flex flex-row pl-7 p-2 mt-16 h-fit w-full border-l-2 border-t-shade-red bg-click-back text-text-white hover:bg-hover-blue"
                            onClick={() => {
                                set_employee_list_state(true);
                                set_history_state(false);
                                set_add_user_state(false);
                            }}
                        >
                            <FaList />
                            <p className=" -mt-1.5 pl-4">Employee List</p>
                        </div>
                    ) : (
                        <div
                            className=" flex flex-row pl-7 p-2 mt-16 h-fit w-full text-text-stone hover:bg-hover-blue"
                            onClick={() => {
                                set_employee_list_state(true);
                                set_history_state(false);
                                set_add_user_state(false);
                            }}
                        >
                            <FaList />
                            <p className=" -mt-1.5 pl-4">Employee List</p>
                        </div>
                    )}

                    {history_state === true ? (
                        <div
                            className=" flex flex-row pl-7 p-2 mt-10 h-fit w-full border-l-2 border-t-shade-red bg-click-back hover:bg-hover-blue"
                            onClick={() => {
                                set_filter(false);
                                set_employee_list_state(false);
                                set_history_state(true);
                                set_add_user_state(false);
                            }}
                        >
                            <FaHistory />
                            <p className=" -mt-1.5 pl-4">History</p>
                        </div>
                    ) : (
                        <div
                            className=" flex flex-row pl-7 p-2 mt-10 h-fit w-full text-text-stone hover:text-text-white hover:bg-hover-blue"
                            onClick={() => {
                                set_employee_list_state(false);
                                set_history_state(true);
                                set_add_user_state(false);
                                // set_csv_state(false);
                            }}
                        >
                            <FaHistory />
                            <p className=" -mt-1.5 pl-4">History</p>
                        </div>
                    )}

                    <div
                        className=" flex flex-row pl-7 p-2 mt-10 h-fit w-full text-text-stone hover:text-text-white hover:bg-hover-blue"
                        onClick={() => {
                            document.cookie = "username=admin001; max-age=0";

                            set_employee_list_state(false);
                            set_history_state(false);
                            set_add_user_state(false);
                            // set_csv_state(false);
                        }}
                    >
                        <FaLock />
                        <p className=" -mt-1.5 pl-4">Logout</p>
                    </div>
                </div>
            </div>

            <div className=" bg-off-white w-full">
                <div className=" bg-bright-white flex flex-col mx-10 mt-2 h-fit rounded-md text-xs ">
                    {employee_list_state && (
                        <div className=" flex flex-row pt-3 ">
                            <p className=" flex-auto text-left pl-2 text-lg font-semibold">
                                Employee List
                            </p>

                            <div className="flex flex-col pr-5 mt-1">
                                <div className=" flex flex-row">
                                    <div className=" mt-1.5 text-text-stone pr-3">
                                        <FaSearch />
                                    </div>
                                    <input
                                        id="search_Id"
                                        type={"text"}
                                        className=" outline-none"
                                        placeholder={"Search..."}
                                        onChange={() => {
                                            setTimeout(() => {
                                                set_search_val(
                                                    document.getElementById("search_Id")?.value
                                                );
                                            }, 300);
                                        }}
                                    ></input>
                                </div>
                                <hr className=" text-text-stone w-full" />
                            </div>

                            <div className=" text-blue pr-5 mt-2 text-lg" onClick={dwnldCsv}>
                                <FaCloudDownloadAlt />
                            </div>
                            <div
                                className=" text-blue pr-5 mt-2 text-sm"
                                onClick={() => {
                                    set_filter(!filter);
                                }}
                            >
                                <FaFilter />
                            </div>
                            <button
                                className=" flex flex-row justify-center items-center bg-blue text-text-white rounded-sm px-1 mr-5 h-9 w-28"
                                onClick={() => {
                                    set_add_user_state(true);
                                    set_employee_list_state(false);
                                    set_filter(false);
                                    set_history_state(false);
                                }}
                            >
                                <FaPlus />
                                <span className=" pl-1 flex content-center text-sm font-semibold">
                                    Add User
                                </span>
                            </button>
                        </div>
                    )}

                    {filter && history_state === false && (
                        <div className=" h-7">
                            <div className="flex justify-center mr-48 font-semibold">
                                <div className=" mr-1">Enroll Status</div>
                                <select
                                    id="rollId"
                                    onChange={() => {
                                        set_status_val(document.getElementById("rollId")?.value);
                                    }}
                                    className=" mr-4 bg-shade-blue text-center"
                                >
                                    <option id="all_statusId" value={"all_roll"}>
                                        ALL
                                    </option>
                                    <option id="enroll_statusId" value={"1"}>
                                        ENROLLED
                                    </option>
                                    <option id="not_enroll_statusId" value={"0"}>
                                        NOT ENROLLED
                                    </option>
                                    <option id="revoke_statusId" value={"3"}>
                                        REVOKED
                                    </option>
                                </select>

                                <div className=" mr-1">Flag Status</div>
                                <select
                                    id="blockId"
                                    onChange={() => {
                                        set_flag_val(document.getElementById("blockId")?.value);
                                    }}
                                    className=" bg-shade-blue text-center"
                                >
                                    <option id="all_blockId" value={"all_block"}>
                                        ALL
                                    </option>
                                    <option id="block_blockId" value={"0"}>
                                        BLOCKED
                                    </option>
                                    <option id="unblock_blockId" value={"1"}>
                                        UNBLOCKED
                                    </option>
                                </select>
                            </div>
                        </div>
                    )}

                    {
                        // filtered csv file download
                        dataHistEmp.map((item, index) => {
                            let csv_item_status =
                                item.status === "1"
                                    ? "Enrolled"
                                    : item.status === "0"
                                    ? "Not Enrolled"
                                    : "Revoked";
                            let csv_item_flag = item.flag === "0" ? "Blocked" : "Unblocked";
                            if (
                                (item.status === status_val || status_val === "all_roll") &&
                                (item.flag === flag_val || flag_val === "all_block")
                            ) {
                                csv_data.push(
                                    item.emp_id +
                                        "," +
                                        item.emp_name +
                                        "," +
                                        csv_item_status +
                                        "," +
                                        csv_item_flag +
                                        "," +
                                        item.last_blocked +
                                        "," +
                                        item.last_unblocked
                                );
                            }
                        })
                    }

                    <div className=" -mt-3">
                        {employee_list_state === true ? (
                            <EmployeeList
                                search_val={search_val}
                                status_val={status_val}
                                flag_val={flag_val}
                            />
                        ) : history_state === true ? (
                            <History />
                        ) : add_user_state === true ? (
                            <AddUser />
                        ) : (
                            navigate("/")
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                pauseOnFocusLoss={false}
             />
        </div>
    ) : (
        navigate("/")
    );
};

export default HomePage_one;
