import axios from "axios";
import React, { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";

const PerEmployee = (props) => {
    let item = props.item;
    let search_val = props.search_val;
    let status_val = props.status_val;
    let flag_val = props.flag_val;
    let [resign, set_resign] = useState(false);

    return (item.status === status_val || status_val === "all_roll") &&
        (item.flag === flag_val || flag_val === "all_block") ? (
        <div
            key={item.emp_id}
            id={item.emp_id}
            className=" hover:bg-shade-blue grid grid-cols-7 p-2"
        >
            <div className="col-span-2">{item.emp_name}</div>
            <div className=" ml-0">{item.emp_id}</div>
            <div className=" ml-0 font-semibold">
                {item.status === "1" ? (
                    <p className=" bg-shade-blue text-blue w-fit px-1 rounded-sm">Enrolled</p>
                ) : item.status === "0" ? (
                    <p className=" bg-shade-black text-black w-fit px-1 rounded-sm">Not Enrolled</p>
                ) : (
                    <p className=" bg-shade-red text-red w-fit px-1 rounded-sm">Revoked</p>
                )}
            </div>
            <div className=" ml-0">{item.flag === "0" ? "Blocked" : "Unblocked"}</div>
            <div className=" -ml-5">
                {item.last_blocked?.length > 0 ? item.last_blocked : "----"}
            </div>
            <div className=" grid grid-cols-6">
                <div className=" col-span-5 -ml-4">
                    {item.last_unblocked?.length > 0 ? item.last_unblocked : "----"}
                </div>

                <div className="row buttons flex">
                    <div className="col -ml-8  mr-4">
                        <button
                            type={"button"}
                            onClick={() => {
                                if(item.loggedIn===1 )
                                    props.logout_func(item.emp_id);
                            }}
                        >
                            <BiLogOut size={20} title={"Logout"} color={item.loggedIn===0 ? "grey":"red"} chg/>
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type={"button"}
                            onClick={() => {
                                set_resign(!resign);
                            }}
                        >
                            <FaEllipsisV size={20} />
                            {resign === true ? (
                                <div>
                                <div
                                    className=" bg-shade-red text-red -ml-24 mt-2 px-1 font-semibold"
                                    onClick={() => {
                                        props.resign_func(item.emp_id);
                                    }}
                                >
                                    Mark as Resigned
                                </div>
                                <div
                                    className=" bg-shade-red text-red -ml-24 mt-2 px-1 font-semibold"
                                    onClick={() => {
                                        props.reset_func(item.emp_id);
                                    }}
                                >
                                    Reset Password
                                </div>
                                </div>
                            ) : null}
                        </button>
                    </div>
                </div>             
            </div>
        </div>
    ) : null;
};

export default PerEmployee;
