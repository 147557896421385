import logo from "./logo.svg";
import "./App.css";
import rrd from "./components/rrd.png";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import EmployeeList from "./components/EmployeeList";
import AdminPage_one from "./components/AdminPage_one";
import HomePage_one from "./components/HomePage_one";

function App() {
    document.title = "Policy App Admin";

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = rrd;

    return (
        <div className="App h-full">
            {/* <AdminPage onLoginClick={OnLoginClick}/>   */}
            <Router>
                {/* <Link to={'/'}>LINK</Link> */}
                {/* <Link to={'/homepage'}></Link> */}

                <Routes>
                    {/* <Route path="/" element={<AdminPage />} />     */}
                    <Route path="/" element={<AdminPage_one />} />
                    <Route path="/homepage" element={<HomePage_one />} />
                    <Route path="/admin_page" element={<AdminPage_one />} />
                    <Route path="/employee_list" element={<EmployeeList />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
