import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaDownload, FaUpload } from "react-icons/fa";
import BaseUrl from "../BaseUrl";

const AddUser = () => {
    window.history.pushState("", "", "/add_user");
    let bulkfile_name;
    let csvfile;
    const [bulk_file, set_bulk_file] = useState();
    const [dwnld_response, set_dwnld_response] = useState([]);
    const formData = new FormData();

    function dwnldCsv() {
        csvfile = dwnld_response;
        // csvfile = ""+'\n';
        // dwnld_response.forEach(element => {
        //           csvfile += element+'\n'
        // });
        csvfile.length > 1 && dwnld("Response.csv", csvfile);
    }

    function dwnld(filename, csvfile) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(csvfile)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    return (
        <div className=" h-60">
            <div className="w-full text-sm">
                <div className=" flex h-12 items-center mt-2 pl-3 justify-start text-lg font-semibold shadow-md">
                    Add New User
                </div>
                <div className=" mt-10">
                    <input
                        className=" font-bold ml-20"
                        type={"file"}
                        accept=".csv"
                        onChange={(e) => {
                            set_bulk_file(e.target.files[0]);
                            bulkfile_name = e.target.files[0].name;
                            // console.log(bulkfile_name, e.target.files[0])
                        }}
                    ></input>
                </div>
                <div className=" flex justify-center mt-6">
                    <div className=" flex items-center content-center font-semibold">
                        <button
                            className=" bg-blue text-text-white flex items-center px-2 mr-8 h-9 w-fit rounded-sm shadow-md shadow-cyan-400"
                            onClick={() => {
                                formData.append("file", bulk_file);

                                if (bulk_file.type === "text/csv") {
                                    axios({
                                        method: "POST",
                                        url: `https://www.camerablocker.io/policy_app/app/add-users`,
                                        // url: "http://54.205.93.121/policy_app/app/add-users",
                                        data: formData,
                                        // headers: {'content-type': 'multipart/form-data'}
                                    })
                                        .then((res) => {
                                            set_dwnld_response(res.data);
                                            alert("file uploaded, download response");
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        });

                                    // alert('file uploaded, download response')
                                } else alert("only .csv files are allowed");
                            }}
                        >
                            <p className=" pr-1">
                                <FaUpload />
                            </p>
                            Upload File
                        </button>

                        <button
                            className=" bg-blue text-text-white flex items-center px-2 h-9 w-fit rounded-sm shadow-md shadow-cyan-400"
                            onClick={dwnldCsv}
                        >
                            <p className=" pr-1">
                                <FaDownload />
                            </p>
                            Download Credentials
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
