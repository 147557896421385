import React, { useEffect, useState } from "react";
import axios from "axios";
import _, { forEach, set } from "lodash";
import { FaSearch, FaSort, FaFileDownload } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import ReactPaginate from "react-paginate";
import PerEmployee from "./PerEmployee";
import BaseUrl from "../BaseUrl";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
    parsePath,
} from "react-router-dom";
import HomePage_one from "./HomePage_one";

let last_index;
const EmployeeList = (props) => {
    window.history.pushState("", "", "/homepage");
    // window.history.pushState("","","http://localhost:3000/rrd/homepage");
    let navigate = useNavigate(null);
    let search_val = props.search_val;
    let status_val = props.status_val;
    let flag_val = props.flag_val;
    // let csv_state = props.csv_state;
    // let csvfile;
    let csv_data = [];

    // const [search_val, set_search_val] = useState('');
    // const [status_val, set_status_val] = useState('all_roll');
    // const [flag_val, set_flag_val] = useState('all_block');
    const [name_sort_state, set_name_sort_state] = useState(false);
    const [sort_state, set_sort_state] = useState(false);
    const [block_sort_state, set_block_sort_state] = useState(false);
    const [unblock_sort_state, set_unblock_sort_state] = useState(false);

    let [data_rec_employees, set_data_rec_employees] = useState([]);
    let [sorted_data_rec_employees, set_sorted_data_rec_employees] = useState([]);
    let [currentItems, setCurrentItems] = useState([]);
    let [pageCount, setPageCount] = useState(0);
    let [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 100;
    let [res_data, set_res_data] = useState("");

    const resign_employee = async(data) => {
        await toast.promise(
            axios({
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                url: `https://www.camerablocker.io/policy_app/app/resigned`,
                // url: "http://54.205.93.121/policy_app/app/resigned",
                data: {
                    emp_id: data,
                },
            })
                .then((res) => {
                    set_res_data(data);
                })
                .catch((e) => {
                    console.log(e);
                }),
            {
              pending: 'Processing!',
              success: 'User Marked as resigned!',
              error: 'Failed!!!'
            }
        );
        
    };
    const logout_emp = async(data) => {
        await toast.promise(
            axios({
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                url: `https://www.camerablocker.io/policy_app/app/logout`,
                // url: "http://54.205.93.121/policy_app/app/resigned",
                data: {
                    emp_id: data,
                },
            }).then((res)=>{
                    if(res.data.status !== "success"){
                        throw new Error("Failed!")
                    } 
                }
            ),
            {
              pending: 'Processing!',
              success: 'User Logged out!',
              error: 'Failed!!!'
            }
        );
    };
    const reset_pass = async(data) => {
        await toast.promise(
            axios({
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                url: `https://www.camerablocker.io/policy_app/app/reset-password`,
                data: {
                    emp_id: data,
                },
            }).then((res)=>{
                    console.log(res.data.status)
                    if(res.data.status !== "success"){
                        throw new Error("Failed!")
                    } 
                }
            ),
            {
              pending: 'Processing!',
              success: 'User Password Reset!',
              error: 'Failed!!!'
            }
        );
    };

    useEffect(() => {
        axios({
            method: "GET",
            url: `https://www.camerablocker.io/policy_app/app/emp-status/999/999`,
            // url: "http://54.205.93.121/policy_app/app/emp-status/999/999"
        })
            .then((response) => {
                set_data_rec_employees(response.data.records);
            })
            .catch((e) => console.log(e));
    }, [res_data]);

    useEffect(() => {
        // console.log('change of res data', res_data)
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(data_rec_employees?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data_rec_employees?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, data_rec_employees, search_val, status_val, flag_val, res_data]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data_rec_employees.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    if (
        search_val === "" &&
        name_sort_state === false &&
        block_sort_state === false &&
        unblock_sort_state === false
    ) {
        sorted_data_rec_employees = _.sortBy(currentItems, "emp_id");
        sort_state === false
            ? (currentItems = currentItems)
            : (currentItems = sorted_data_rec_employees);
    } else if (
        search_val === "" &&
        name_sort_state === true &&
        block_sort_state === false &&
        unblock_sort_state === false
    ) {
        sorted_data_rec_employees = _.orderBy(currentItems, ["emp_name"], ["desc"]);
        // console.log(sorted_data_rec_employees)
        name_sort_state === false
            ? (currentItems = currentItems)
            : (currentItems = sorted_data_rec_employees);
    } else if (
        search_val === "" &&
        name_sort_state === false &&
        block_sort_state === true &&
        unblock_sort_state === false
    ) {
        sorted_data_rec_employees = _.orderBy(currentItems, ["last_blocked"], ["desc"]);
        block_sort_state === false
            ? (currentItems = currentItems)
            : (currentItems = sorted_data_rec_employees);
    } else if (
        search_val === "" &&
        name_sort_state === false &&
        block_sort_state === false &&
        unblock_sort_state === true
    ) {
        sorted_data_rec_employees = _.orderBy(currentItems, ["last_unblocked"], ["asc"]);
        unblock_sort_state === false
            ? (currentItems = currentItems)
            : (currentItems = sorted_data_rec_employees);
    } else if (search_val !== "") {
        sorted_data_rec_employees = _.sortBy(data_rec_employees, "emp_id");
        sort_state === false
            ? (currentItems = data_rec_employees)
            : (currentItems = sorted_data_rec_employees);
    }

    return (
        <div>
            <div className="filter-options mt-5">
                {/* { csv_state && console.log(csv_state)} */}

                <hr className="mt-6 mx-0" />

                <div className=" grid grid-cols-7 m-2 gap-1 font-bold text-left">
                    <div className=" col-span-2 ml-1">
                        Name
                        <button
                            id="sortId"
                            onClick={() => {
                                set_name_sort_state(!name_sort_state);
                                set_sort_state(false);
                                set_block_sort_state(false);
                                set_unblock_sort_state(false);
                            }}
                            className=" w-fit rounded-lg text-xs ml-2 shadow-md"
                        >
                            <div className="">
                                <FaSort />
                            </div>
                        </button>
                    </div>

                    <div className=" col-span-1">
                        Id
                        <button
                            id="sortId"
                            onClick={() => {
                                set_name_sort_state(false);
                                set_sort_state(!sort_state);
                                set_block_sort_state(false);
                                set_unblock_sort_state(false);
                            }}
                            className=" w-fit rounded-lg text-xs ml-2 shadow-md"
                        >
                            <div className="">
                                <FaSort />
                            </div>
                        </button>
                    </div>

                    <div className=" col-span-1 -ml-2">Enrolled Status</div>

                    <div className=" col-span-1 -ml-3">Block Status</div>

                    <div className=" col-span-1 -ml-8">
                        Last Blocked
                        <button
                            id="sortId"
                            onClick={() => {
                                set_name_sort_state(false);
                                set_sort_state(false);
                                set_block_sort_state(!block_sort_state);
                                set_unblock_sort_state(false);
                            }}
                            className=" w-fit rounded-lg text-xs ml-2 shadow-md"
                        >
                            <div className="">
                                <FaSort />
                            </div>
                        </button>
                    </div>

                    <div className=" col-span-1 -ml-8">
                        Last Unblocked
                        <button
                            id="sortId"
                            onClick={() => {
                                set_name_sort_state(false);
                                set_sort_state(false);
                                set_block_sort_state(false);
                                set_unblock_sort_state(!unblock_sort_state);
                            }}
                            className=" w-fit rounded-lg text-xs ml-2 shadow-md"
                        >
                            <div className="">
                                <FaSort />
                            </div>
                        </button>
                    </div>
                </div>
                <hr />
            </div>

            {
                // filtered csv file download
                data_rec_employees.map((item, index) => {
                    let csv_item_status =
                        item.status === "1"
                            ? "Enrolled"
                            : item.status === "0"
                            ? "Not Enrolled"
                            : "Revoked";
                    let csv_item_flag = item.flag === "0" ? "Unblocked" : "Blocked";
                    if (
                        (item.status === status_val || status_val === "all_roll") &&
                        (item.flag === flag_val || flag_val === "all_block")
                    ) {
                        csv_data.push(
                            item.emp_id +
                                "," +
                                item.emp_name +
                                "," +
                                csv_item_status +
                                "," +
                                csv_item_flag +
                                "," +
                                item.last_blocked +
                                "," +
                                item.last_unblocked
                        );
                    }
                })
            }

            {/* displaylist */}
            <DisplayList />

            <div className="mt-3">
                {last_index}-
                {data_rec_employees.length > last_index + 99
                    ? last_index + 99
                    : data_rec_employees.length}{" "}
                of {data_rec_employees.length}
            </div>

            <div className="paginate mt-4">
                <ul className="paginateList">
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={"break-me"}
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </ul>
            </div>
        </div>
    );

    function DisplayList() {
        return (
            <div className=" text-left mt-5 ml-1 overflow-y-scroll shadow-sm shadow-cyan-400" style={{height:600}}>
                {currentItems.length === 0 ? (
                    <div className="flex justify-center mt-5">
                        <ClipLoader color="cyan" />
                    </div>
                ) : (
                    currentItems.map((item, index) => {
                        last_index = itemOffset + 1;
                        if (search_val === "" && item.status !== "3") {
                            return (
                                <div key={index}>
                                    <PerEmployee
                                        resign_func={resign_employee}
                                        logout_func={logout_emp}
                                        reset_func={reset_pass}
                                        item={item}
                                        search_val={search_val}
                                        status_val={status_val}
                                        flag_val={flag_val}
                                    />
                                </div>
                            );
                        } else if (search_val === "" && item.status == "3") {
                            return (
                                <div key={index} className=" bg-cyan-700">
                                    <PerEmployee
                                        resign_func={resign_employee}
                                        reset_func={reset_pass}
                                        logout_func={logout_emp}
                                        item={item}
                                        search_val={search_val}
                                        status_val={status_val}
                                        flag_val={flag_val}
                                    />
                                </div>
                            );
                        } else if (
                            search_val !== "" &&
                            item.status !== "3" &&
                            (item.emp_name.toLowerCase().includes(search_val) ||
                                item.emp_id.toLowerCase().includes(search_val) ||
                                item.emp_name.includes(search_val) ||
                                item.emp_id.includes(search_val))
                        ) {
                            return (
                                <div key={index}>
                                    <PerEmployee
                                        resign_func={resign_employee}
                                        logout_func={logout_emp}
                                        reset_func={reset_pass}
                                        item={item}
                                        search_val={search_val}
                                        status_val={status_val}
                                        flag_val={flag_val}
                                    />
                                </div>
                            );
                        } else if (
                            search_val !== "" &&
                            item.status === "3" &&
                            (item.emp_name.toLowerCase().includes(search_val) ||
                                item.emp_id.toLowerCase().includes(search_val) ||
                                item.emp_name.includes(search_val) ||
                                item.emp_id.includes(search_val))
                        ) {
                            return (
                                <div key={index} className="bg-cyan-600">
                                    <PerEmployee
                                        resign_func={resign_employee}
                                        logout_func={logout_emp}
                                        reset_func={reset_pass}
                                        item={item}
                                        search_val={search_val}
                                        status_val={status_val}
                                        flag_val={flag_val}
                                    />
                                </div>
                            );
                        }
                    })
                )}
            </div>
        );
    }
};

export default EmployeeList;
