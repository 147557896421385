import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Group from "./images/Group.png";

const AdminPage_one = () => {
    window.history.pushState("", "", "");
    // window.history.pushState("","","http://localhost:3000/rrd")
    let navigate = useNavigate(null);
    const [username, setUserName] = useState("");
    const [pass, setPass] = useState("");
    const [previous_cookies, set_previous_cookies] = useState(document.cookie);

    useEffect(() => {
        document.cookie = `${previous_cookies}; max-age=0`;
        // console.log("admin_page-->",document.cookie);
    }, [previous_cookies]);

    const onLoginClick = () => {
        axios({
            method: "POST",
            url: "https://www.camerablocker.io/policy_app/app/admin-login",
            data: {
                admin_id: username,
                password: pass,
            },
        })
            .then((res) =>
                res.data.status === "success"
                    ? ((document.cookie = "username=" + username), navigate("/homepage"))
                    : alert("Invalid Login Credentials")
            )
            .catch((e) => e);
    };

    return (
        <div className="bg-dark-blue flex h-screen content-center text-text-white">
            <div className=" w-screen mx-72 mt-14 h-5/6">
                <div className=" flex justify-center mt-24 lg:h-fit sm:h-12">
                    <img src={Group}></img>
                </div>
                <div className=" flex flex-col lg:ml-40 sm:ml-20 w-3/5 mt-2">
                    <label className=" text-left h-4 mb-2">Employee ID</label>
                    <input
                        type={"text"}
                        className=" text-black rounded-sm py-2 pl-1"
                        onChange={(e) => {
                            setUserName(e.target.value);
                        }}
                    ></input>
                </div>
                <div
                    className=" flex flex-col lg:ml-40 sm:ml-20 w-3/5 mt-4"
                    onKeyUp={(e) => {
                        e.code === "Enter" && onLoginClick();
                    }}
                >
                    <label className=" text-left h-4 mb-2">Password</label>
                    <input
                        type={"password"}
                        className=" text-black rounded-sm py-2 pl-1"
                        onChange={(e) => {
                            setPass(e.target.value);
                        }}
                    ></input>
                </div>
                <div className=" flex flex-col lg:ml-40 sm:ml-20 w-3/5 mt-6">
                    <button className=" bg-login rounded-sm py-2" onClick={onLoginClick}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminPage_one;
