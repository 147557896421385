import React, { useState } from "react";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";

const History = (props) => {
    window.history.pushState("", "", "/history");
    // let [data_rec_employees, set_data_rec_employees] = useState([]);
    let [from_date, set_from_date] = useState();
    let [to_date, set_to_date] = useState();
    let csvfile;

    async function get_history() {
        try {
            const result = await toast.promise(
                axios.get(
                    `https://www.camerablocker.io/policy_app/app/get-history/${from_date}/${to_date}`
                ),
                {
                    pending: "Getting History...",
                    success: "Done!",
                    error: "Request failed",
                    // Optionally, you can customize the toast appearance using the `toastOptions` object
                    toastOptions: {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    },
                }
            );
            // set_data_rec_employees(result.data.data);
            let history = result.data.data;
            csvfile = "Employee_Id,Employee_Name,Blocked_Status,Added_On\n";
            if (history.length > 1) {
                history.forEach((element) => {
                    csvfile += Object.values(element) + "\n";
                });
                dwnld("EmployeeHistory.csv", csvfile);
            } else {
                alert("no employees found for given dates");
            }
        } catch (e) {
            alert(e.response.data.descrption);
            // set_data_rec_employees([]);
        }
    }

    return (
        <div className=" h-60">
            <div className=" flex justify-start shadow-md shadow-lime-300 h-12 mt-2 pl-3 items-center text-lg font-semibold">
                History
            </div>
            <div className=" flex justify-center mt-10">
                <div className="flex flex-col text-sm"></div>

                <div className=" flex flex-row font-semibold text-sm">
                    <div className=" mr-5">
                        <label className="flex justify-start">From Date</label>
                        <input
                            type={"date"}
                            id="from_date_id"
                            className=" bg-shade-blue h-9 px-2 rounded-sm"
                            onChange={() => {
                                set_from_date(document.getElementById("from_date_id")?.value);
                            }}
                        ></input>
                    </div>

                    <div>
                        <label className="flex justify-start">To Date</label>
                        <input
                            type={"date"}
                            id="to_date_id"
                            className=" bg-shade-blue px-2 h-9 mb-8 rounded-sm"
                            onChange={() => {
                                set_to_date(document.getElementById("to_date_id")?.value);
                            }}
                        ></input>
                    </div>

                    <button
                        onClick={async () => {
                            await get_history();
                        }}
                        className=" bg-blue text-text-white flex justify-center  items-center ml-3 mt-5 px-2 h-9 w-fit rounded-sm shadow-md shadow-cyan-400"
                    >
                        <p className=" mt-0.5 mr-1">
                            <FaDownload></FaDownload>
                        </p>
                        Download CSV
                    </button>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );

    function dwnld(filename, csvfile) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(csvfile)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
};

export default History;
